<template>
  <div class="zt-page-content" ref="load">
    <div v-if="step==1" class="step1">
      <EditStep1 ref="form_check1" :form="ruleForm" @step="addStep"></EditStep1>
    </div>
    <div v-if="step>=2" class="step2">
      <EditStep2 ref="form_check2" :form="ruleForm" @step="addStep"></EditStep2>
    </div>

    <div class="zt-block" style="padding: 20px 0">
      <div class="flex flex-justify-around" style="max-width:400px;margin:0 auto;">
        <el-button size="small" type="primary" @click="back()">取消</el-button>
        <!-- <el-button size="small" @click="saveDraft()" type="primary" plain>存草稿</el-button> -->
        <el-button v-if="step<2" size="small" @click="submitForm()" type="primary">下一步</el-button>
        <el-button v-if="step>=2" size="small" @click="lastStep()" type="primary">上一步</el-button>
        <el-button v-if="step>=2 && ruleForm.price_type==4" size="small" @click="submitForm()" type="primary">保存</el-button>
        <el-button v-if="step>=2 && ruleForm.price_type!=4" size="small" @click="submitForm()" type="primary">发布</el-button>
      </div>
    </div>

    <el-dialog width="550px" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
      <div v-if="popTitle=='温馨提示'">
        <div style="width:80%;margin:0 auto;text-align:center;padding-bottom:20px;">
          <p style="font-size:16px;color:#292929;">作品发布确认</p>
          <p style="font-size:14px;color:#808080;">作品发布后，工厂即可在设计超市中浏览和购买，请仔细核对作品详情和价格</p>
        </div>
        <div style="text-align:center;border-top:1px solid #ccc;padding-top:25px;">
          <el-button
            @click="submitHide()"
            style="margin-right:20px;"
            size="small"
            type="primary"
            plain
          >再修改一下</el-button>
          <el-button @click="submit" size="small" type="primary">确认并发布</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { clone } from 'lodash';
import { singleDetail, addOrUpdateProduct } from "@/service/design";
import EditStep1 from "./EditStep1";
import EditStep2 from "./EditStep2";
import designWorkStorage from "@/utils/designWorkStorage";
export default {
  components: {
    EditStep1,
    EditStep2
  },
  computed: {},
  data() {
    return {
      popTitle: null,
      popVisible: false,
      step: 1,
      ruleForm: {
        id: ""
        // name: "",
        // sname: "",
        // // module_no:null,
        // style_no: "",
        // cat_id: "",
        // attr: [],
        // intro:"",
        // design_idea:"",
        // dimension: [],
        // photo_render:[],
        // photo_cad:[],
        // photo_size:[],
        // photo_story:[],
        // accessory:[],
        // price:"",
        // price_type: 0,
        // contract_file: "",
        // custom_cover_page: 0,
        // price_percent: "", // 设计公司分成百分比x100，范围0--10000
        // order_id: "", // 订单id，用于需求定制订单
        // order_intro: "", // 订单备注，用于需求定制订单
      }
    };
  },
  async created() {
    this.ruleForm.id = this.$route.query.id;
    if (this.ruleForm.id) {
      this.refreshItem();
    }
  },
  mounted() {},
  methods: {
    back() {
      this.$router.go(-1);
    },
    refreshItem() {
      singleDetail(this.ruleForm.id)
        .then(rst => {
          console.log("rst", rst);
          if (rst) {
            this.ruleForm = {
              id: rst.id,
              name: rst.name,
              sname: rst.sname,
              // module_no: rst.module_no,
              intro: rst.intro,
              set_no: rst.set_no,
              style_no: rst.style.id,
              cat_id: rst.cat.id,
              attr: rst.attr,
              design_idea: rst.design_idea,
              dimension: rst.dimension,
              photo_render: rst.photo_render,
              photo_cad: rst.photo_cad,
              photo_size: rst.photo_size,
              photo_story: rst.photo_story,
              accessory: rst.accessory,
              price_type: rst.price_type,
              price: rst.price,
              contract_file: rst.contract_file,
              custom_cover_page: rst.custom_cover_page,
              // order_id: rst.order_id // 订单id，用于需求定制订单
              // order_intro: rst.order_intro, // 订单备注，用于需求定制订单
            };
            if (rst.price_percent) {
              this.ruleForm.price_percent =
                Math.floor(rst.price_percent * 100) / 10000;
            }
          }
          console.log("ruleForm", this.ruleForm);
        })
        .catch(err => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    lastStep() {
      // 上一步
      this.step--;
    },
    addStep(data, stepNum) {
      // this.ruleForm = data;
      Object.assign(this.ruleForm, data);
      console.log("ruleForm", this.ruleForm);
      if (this.step == 1) {
        // this.submit();
      } else if (this.step >= 2) {
        this.submitShow();
      }
      this.step = stepNum;
    },
    submitForm() {
      // 下一步
      if (this.step == 1) {
        this.$refs.form_check1.submitForm1();
      } else if (this.step >= 2) {
        this.$refs.form_check2.submitForm2();
      }
    },
    submitHide() {
      this.popVisible = false;
    },
    submitShow() {
      if (this.ruleForm.price_type==4) {
        this.$confirm("确定要保存？", "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            closeOnClickModal: false,
            type: "warning",
        }).then(() => {
            this.submit();
        });
      } else {
        this.popTitle = "温馨提示";
        this.popVisible = true;
      }
    },
    submit() {
      const _loading = this.$loading({
        lock: true,
        text: "拼命加载中......"
      });
      let _item = clone(this.ruleForm);
      if (_item.price_percent) {
          _item.price_percent = Math.round(_item.price_percent * 100);
      }
      addOrUpdateProduct(_item)
        .then(rst => {
          // this.step++;
          console.log("this.step", this.step);
          if (this.step > 2) {
            this.$message.success("上传成功");
            if (this.$route.query.orderId) {
              // 需求定制订单跳转过来创建单品，type=3单品，type=4套系
              let obj = designWorkStorage.getCommitDesignWork();
              if (!obj.single) {
                obj.single = [{ id: rst.id, name: rst.name }];
              } else {
                obj.single.push({ id: rst.id, name: rst.name });
              }
              designWorkStorage.setCommitDesignWork(obj);
              this.$router.push(
                "/want/demandCustomize/customizeDetail/" +
                  this.$route.query.orderId +
                  "?type=3"
              );
            } else {
              this.$router.push("/desgin/stored");
            }
            this.submitHide();
          } else {
            designWorkStorage.removeCommitDesignWork();
          }
          _loading.close();
        })
        .catch(err => {
          designWorkStorage.removeCommitDesignWork();
          _loading.close();
          this.$message.error(err.message);
        });
    }
  }
};
</script>
<style scoped lang="less">
</style>